.main-container {
  /* box-shadow: 3px 3px 10px 6px #e9e9ed */
  /* border: 1px solid gray; */
  border-bottom-left-radius: 50px 20px;
  border-bottom-right-radius: 50px 20px;
  padding: 32px;
  background-color: #f7f7f9;
  border-top: none;
  margin-top: 30px !important;
}

.MuiTab-root {
  min-width: 162px !important;
}
@media (min-width: 2560px) {
  .MuiTab-root {
    min-width: 195px !important;
  }
  .show-text-font {
    /* padding: 75px !important; */
    font-size: 140px !important;
  }
}

@media (min-width: 1440px) {
  .MuiTab-root {
    min-width: 195px !important;
  }
  .show-text-font {
    /* padding: 100px !important; */
    font-size: 120px !important;
  }
  .background-clr {
    background-size: 80px 105px !important;
  }
}

@media (max-width: 1024px) {
  .MuiTab-root {
    min-width: 135px !important;
  }
  .show-text-font {
    /* padding: 75px !important; */
    font-size: 110px !important;
  }
  .background-clr {
    background-size: 63px 83px !important;
  }
  /* .show-text-font {
  padding: 75px !important;
  font-size: 140px !important;

} */
}
@media (max-width: 768px) {
  .tab-heading {
    text-align: center !important;
  }
  .fonthide span {
    font-size: 0;
    min-width: none;
  }
  .MuiTab-root {
    min-width: 95px !important;
  }
  .headings-left {
    font-size: 10px !important;
  }
  .headings-right {
    font-size: 10px !important;
  }
  .headings-center {
    font-size: 10px !important;
  }
  .heading-3 {
    font-size: 10px !important;
    font-weight: 500;
  }
  .check-box {
    margin-top: 8px !important;
  }
  .form-check-label {
    font-size: 10px;
  }
  .effect-images {
    border: 1px solid #ced4da;
    height: 35px;
    width: 53px !important;
    padding: 4px;
    border-radius: 5px;
    cursor: pointer;
    margin: 0px 0px 10px 0px !important;
  }
  .input-group {
    width: 100% !important;
  }
  .tab-mt {
    margin-top: 10px !important;
    margin-bottom: 5px !important;
  }
  .align-icon {
    margin-top: 0px !important;
  }
  .css-i4bv87-MuiSvgIcon-root {
    font-size: 1rem !important;
  }
  .graphic-images {
    width: 32px !important;
    height: 55px !important;
  }
  .col-sm-1 {
    width: 14.333333% !important;
    margin-left: 45px !important;
    margin-top: 10px !important;
  }
  .col-sm-6 {
    width: 100% !important;
  }
  .show-text-font {
    /* padding: 60px !important; */
    font-size: 70px !important;
  }
  .background-clr {
    background-size: 55px 60px !important;
  }
}
@media (max-width: 425px) {
  .MuiTab-root {
    min-width: 50px !important;
    padding: 6px 6px !important;
  }
  .MuiTab-labelIcon {
    padding-top: 5px !important;
  }
  .MuiButtonBase-root {
    margin: 20px 1px 5px !important;
  }
  .show-text-font {
    /* padding: 35px !important; */
    font-size: 40px !important;
  }

  .mob-mt {
    margin-top: 15px !important;
    margin-bottom: 0px !important;
  }
  .mob-heading {
    text-align: center !important;
  }
  #font-picker {
    width: 94% !important;
  }
  .align-icon {
    margin-top: 0px !important;
  }
  .css-i4bv87-MuiSvgIcon-root {
    font-size: 0.6rem !important;
  }
  .headings-left {
    font-size: 10px !important;
  }
  .headings-right {
    font-size: 10px !important;
  }
  .headings-center {
    font-size: 10px !important;
  }
  .heading-3 {
    font-size: 10px !important;
    font-weight: 500;
  }
  .check-box {
    margin-top: 8px !important;
  }
  .form-check-label {
    font-size: 10px;
  }

  .background-clr {
    background-size: 30px 35px !important;
  }
}

.MuiTab-labelIcon {
  min-height: 40px !important;
}
.css-tzssek-MuiSvgIcon-root {
  height: 0.7em !important;
}
.align-icon {
  margin-top: 20px;
}
.input-group {
  margin-top: 10px;
  width: 100%;
}

.MuiTabs-flexContainer {
  width: 100%;
}
.MuiTab-labelIcon {
  min-height: 50px;
  padding-top: 10px;
}

.headings-left {
  text-align: left;
  font-size: large;
  font-weight: 600;
}
.headings-right {
  text-align: right;
  font-size: large;
  font-weight: 600;
}
.headings-center {
  text-align: center;
  font-size: large;
  font-weight: 600;
  cursor: pointer;
}
.heading-3 {
  font-size: large;
  font-weight: 500;
}
.textarea {
  resize: none;
}

.text-p3 {
  margin-top: 1rem;
  font-size: 16px;
}
.text-p5 {
  font-size: 12px;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.btn-size {
  margin: 10px;
  text-align: center;
}
.MuiTabPanel-root {
  padding: 0px !important;
  margin-bottom: 20px;
}

.align-icon {
  cursor: pointer;
  margin-right: 10px;
}
.check-box {
  margin-top: 20px;
}
.checkbox-r-italic {
  font-style: italic;
}
.label-test label {
  -moz-transform: scale(1) rotate(0deg) translate(0px, 0px) skew(20deg, 0deg);
  -webkit-transform: scale(1) rotate(0deg) translate(0px, 0px) skew(20deg, 0deg);
  -o-transform: scale(1) rotate(0deg) translate(0px, 0px) skew(20deg, 0deg);
  -ms-transform: scale(1) rotate(0deg) translate(0px, 0px) skew(20deg, 0deg);
  transform: scale(1) rotate(0deg) translate(0px, 0px) skew(20deg, 0deg);
}
.checkbox-bold {
  font-weight: 700;
}
.same-height {
  /* font-family: Arial, sans-serif; */
  /* display:inline-block; */
  /* -webkit-transform:scale(1,2); */
  /* Safari and Chrome */
  font-variant-caps: small-caps;
  font-style: italic;
}

.bolditalic-icon {
  font-weight: 500;
}
.sub-heading-light {
  color: gray;
  font-size: small;
  font-weight: 400;
}
.sub-heading-dark {
  color: rgb(0, 0, 0);
  font-size: small;
  font-weight: 400;
}
.m-auto {
  margin: auto;
}
.margin-top-xl {
  margin-top: 30px;
}
.margin-top-xs {
  margin-top: 5px;
}
.margin-top {
  margin-top: 20px;
}
.margin-bottom {
  margin-bottom: 10px;
}
.shadow-effects-left {
  border-radius: 20px;
  box-shadow: -5px -5px 1px #ccc;
}
.shadow-effects-right {
  border-radius: 20px;
  box-shadow: 5px -5px 1px #ccc;
}

.shadow-effects-top {
  border-radius: 20px;
  box-shadow: -5px 5px 1px #ccc;
}
.shadow-effects-bottom {
  border-radius: 20px;
  box-shadow: 5px 5px 1px #ccc;
}
.background-clr {
  border: 0px;
  border-radius: 8px;
  background-size: 80px 105px;
}
.show-text {
  border: 1px solid gray;
  border-radius: 10px;
  /* padding: 10px 32px 10px 32px; */
  background-color: transparent;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  height: 100%;
}
.show-text-font {
  /* padding: 100px; */
  font-size: 120px;
  margin-bottom: 0rem !important;
  margin-top: 0rem !important;
}
#font-picker {
  box-shadow: 3px 3px 10px 6px rgb(241, 235, 235);
  border: 1px solid #ced4da;
  width: 80%;
  margin-left: 10px;
  border-radius: 3px;
  background-color: white;
}

.dropdown-button {
  background-color: white !important;
}

.dropdown-icon {
  visibility: hidden;
}

.font-list::-webkit-scrollbar {
  width: 100% !important;
  margin-left: -12px !important;
  background-color: white;
  display: none;
}

.font-list {
  margin-left: -12px !important;
  width: 100% !important;
  background-color: white !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.effect-images {
  border: 1px solid #ced4da;
  height: 35px;
  width: 65px;
  padding: 4px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0px 10px 0px;
}
.style-box-shadow {
  box-shadow: 3px 3px 10px 6px #e9e9ed;
}
.graphic-images {
  width: 60px;
  height: 70px;
  cursor: pointer;
}
.border-none {
  height: 30px;
  width: 30px;
  margin-left: 160px;
  cursor: pointer;
}

.gradient-none {
  height: 30px;
  width: 30px;
  margin-left: 150px;
  cursor: pointer;
}

.font-none {
  height: 30px;
  width: 30px;
  margin-left: 120px;
  cursor: pointer;
}
