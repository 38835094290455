.App {
  text-align: center;
  background-color: #f7f7f8;
  margin-bottom: 20px;

}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.container{
  margin-top: 30px !important;
}
.inside-container{

  box-shadow: 3px 3px 10px 6px rgb(194, 194, 194);
  padding: 2rem;  
  margin: 20px 10px 10px 10px !important;
 /* margin:  10px !important;  */
  
  background-color: white;
}
 @media (min-width:1440px){
  .inside-container{
    margin: 30px 0px 2px 60px !important;
    }
}
@media (min-width:1024px){
  .inside-container{
    margin: 30px 0px 10px 30px!important;
    }
}
@media (min-width:768px){
  .inside-container{
    margin: 20px 0px 10px 24px!important;
    }
}
@media (min-width:425px){
  .inside-container{
    margin: 0px 0px 0px 0px!important;
    }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
